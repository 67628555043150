import React from "react";
import logo from "./assets/hey-honey-logo.png";
import "./App.css";
import { AiOutlineShop } from "react-icons/ai";
import { FiInstagram } from "react-icons/fi";

function App() {
  return (
    <div className="App">
      <img src={logo} className="App-logo" alt="logo" />
      <div className="icon-section">
        <a href="https://www.instagram.com/heyhoney.lettering/">
          <FiInstagram color={"#98ad84"} size={30} />
        </a>
        <a href="https://www.etsy.com/de/shop/heyhoneyLettering?ref=shop_sugg">
          <AiOutlineShop color={"#98ad84"} size={30} />
        </a>
      </div>
    </div>
  );
}

export default App;
